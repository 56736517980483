import React, { useState, useEffect, useCallback, useMemo } from 'react'
import {  Container, } from '@mui/material'
import { useMediaQuery } from '@mui/material'
import DrawerTheme from '../../../theme/dashboard_themes/drawerTheme'
import { ThemeProvider } from '@mui/material/styles'
import Header from './Header'
import DashboardGrid from './Grid'
import RunningAPI from '../../../utilities/apiClient'
import { processData } from './chart_components/LineChart'

/**
 * DashboardComponent is a functional component that renders a dashboard with various cards and features.
 * 
 *
 * @returns {JSX.Element} The rendered DashboardComponent
 */

const DashboardComponent = ({setIsAuthenticated}) => {

    const userInfo = JSON.parse(localStorage.getItem('userInfo')) || { username: 'Guest' }

    const [drawerOpen, setDrawerOpen] = useState(false)
    const isMobile = useMediaQuery('(max-width:600px)')

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open)
    }

    const [recentRuns, setRecentRuns] = useState([])
    const [awaitingRunData, setAwaitingRunData] = useState(true)
    const [error, setError] = useState(null)
    const api = useMemo(() => new RunningAPI(), [])

    const fetchRecentRuns = useCallback(async () => {
      setAwaitingRunData(true)
      const allRuns = await api.getData('run')
      if (allRuns.length === 0) {
          setError('No runs available')
      } else {
          const sortedRuns = allRuns.sort((a, b) => new Date(b.finished) - new Date(a.finished))
          const recentRuns = sortedRuns.slice(0, 5)
          setRecentRuns(recentRuns)
      }
      setAwaitingRunData(false)
    }, [api])
  
    const [chartData, setChartData] = useState({ datasets: [], allDates: [] })
    const [chartError, setChartError] = useState(null)
    const [loading, setLoading] = useState(true)
    
    const fetchChartData = useCallback(async () => {
        setLoading(true)
        try {
            const response = await api.getData('run/chart')
            if (response) {
                const processedData = processData(response.data)
                setChartData(processedData)
            } else {
                setChartError('No runs available')
            }
        } catch (err) {
            setChartError('Failed to fetch data')
        } finally {
            setLoading(false)
        }
    }, [api])
    
    const [bestRun, setBestRun] = useState(null)
    const [bestRunLoading, setbestRunLoading] = useState(true)
    const [bestRunError, setBestRunError] = useState(null)
    
    const fetchBestRun = useCallback(async () => {
        setbestRunLoading(true)
        try {
            const response = await api.getData('run/best')
            if (response && Object.keys(response).length > 0) {
                setBestRun(response)
            } else {
                setBestRunError('No runs available')
            }
        } catch (err) {
            setBestRunError('Failed to fetch your best run')
        } finally {
            setbestRunLoading(false)
        }
    }, [api])
    
    const [mostRecentRun, setMostRecentRun] = useState(null)
    const [mostRecentRunLoading, setMostRecentRunLoading] = useState(true)
    const [mostRecentRunError, setMostRecentRunError] = useState(null)
    
    const fetchMostRecentRun = useCallback(async () => {
        setMostRecentRunLoading(true)
        try {
            const response = await api.getData('run/recent')
            if (response) {
                setMostRecentRun(response)
            } else {
                setMostRecentRunError('No runs available')
            }
        } catch (err) {
            setMostRecentRunError('Failed to fetch your most recent run')
        } finally {
            setMostRecentRunLoading(false)
        }
    }, [api])
    
    useEffect(() => {
        fetchRecentRuns()
        fetchChartData()
        fetchBestRun()
        fetchMostRecentRun()
    }, [fetchRecentRuns, fetchChartData, fetchBestRun, fetchMostRecentRun])

    return (
        
        {/* Drawer Theme */},
        <ThemeProvider theme={DrawerTheme}>
            {/* Main Container */}
            <Container maxWidth={false} sx={{ height: '100vh', minHeight:'100vh', overflow:'auto', backgroundColor: '#263238', width: '100%' }}>

                {/* Header Component */}
                <Header isMobile={isMobile} userInfo={userInfo} toggleDrawer={toggleDrawer} drawerOpen={drawerOpen} reloadDashboard={fetchRecentRuns}
                reloadChart={fetchChartData} reloadBestRun={fetchBestRun} 
                reloadMostRecentRun={fetchMostRecentRun}/>
                
                {/* Grid Component */}
                <DashboardGrid recentRuns={recentRuns} awaitingRunData={awaitingRunData} error={error}
                chartData={chartData}
                loading={loading}
                chartError={chartError}
                bestRun={bestRun}
                bestRunLoading={bestRunLoading}
                bestRunError={bestRunError}
                mostRecentRun={mostRecentRun}
                mostRecentRunLoading={mostRecentRunLoading}
                mostRecentRunError={mostRecentRunError}
                reloadBestRun={fetchBestRun}
                reloadChart={fetchChartData}
                reloadDashboard={fetchRecentRuns}
                reloadMostRecentRun={fetchMostRecentRun}
                />

            </Container>
    </ThemeProvider>

    )
}

export default DashboardComponent