import RunningAPI from '../../../../utilities/apiClient'

const api = new RunningAPI()

const postRoute = async (routeName, routeDistance) => {

    try {
        const response = await api.postData(`run/routes/`, 
            {
              name: routeName,
              distance: routeDistance,
            },
          )
        
        return response
    } catch(error) {
        console.error("Couldn't retrieve response for routes")
    }
    }

export default postRoute