import { Box, Typography, Skeleton, useMediaQuery } from '@mui/material'
import { ThemeProvider} from '@mui/material/styles'
import tileTheme from '../../../theme/dashboard_themes/tileTheme'
import { useState } from 'react'
import { blueGrey } from '@mui/material/colors'
import EditRunModal from './editRunComponents/EditRunModal'

const PersonalBestTile = ({theme, gridBoxStyle, tileBaseStyle, bestRun, bestRunLoading, 
    bestRunError, retreiveBestRun, updateChart, updateTable, updateMostRecentRun
}) => {
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)

    const handleTileClick = () => {
        if (bestRun) {
            setIsEditModalOpen(true)
        }
    }

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false)
    }
    return (
        <>
            <Box 
                sx={{...gridBoxStyle, cursor: bestRun ? 'pointer' : 'default'}} 
                onClick={handleTileClick}
            >
                <ThemeProvider theme={tileTheme}>
                    {bestRunLoading ? (
                        <Skeleton data-testid="skeleton-loader" variant="square" width='100%' height='100%'/>
                    ) : bestRun ? (
                        <>
                        <Typography sx={tileBaseStyle} variant={isMobile ? 'body1' : 'h6'}>Personal Best</Typography>
                        <Typography sx={{...tileBaseStyle, padding: '0'}} variant={isMobile ? 'h4' : 'h2'} color={blueGrey[50]}>{bestRun.duration}</Typography>
                        <Typography sx={{...tileBaseStyle, paddingBottom: '0'}}  variant={isMobile ? 'h5' : 'h4'}>{bestRun.date}</Typography>
                        <Typography sx={{...tileBaseStyle, padding: '0'}}  variant={isMobile ? 'body1' : 'h6'}>{bestRun.route}</Typography>
                        </>
                    ) : (
                        <>
                            <Typography data-testid="no-runs-available" sx={{tileBaseStyle, textAlign: 'center'}} variant='h4'>{bestRunError}</Typography>
                        </>
                    )}
                </ThemeProvider>
            </Box>
            {bestRun && (
                <EditRunModal 
                    open={isEditModalOpen}
                    handleClose={handleCloseEditModal}
                    run={bestRun}
                    onRunUpdated={retreiveBestRun}
                    updateChart={updateChart}
                    updateTable={updateTable}
                    reloadRecentOrBest={updateMostRecentRun}
                />
            )}
        </>
    )
}


export default PersonalBestTile