import RunningAPI from '../../../../utilities/apiClient'

/**
 * Fetches routes from the API.
 * @returns {Promise<Array>} The array of route objects.
 */

const api = new RunningAPI()

const fetchRoutes = async () => {
  try {
    const response = await api.getData(`run/routes/`)
    const responseRoutesArray = Object.values(response)
    return responseRoutesArray

  } catch (error) {
    console.error("Couldn't retrieve response for routes")
    return []
  }
}

export default fetchRoutes
