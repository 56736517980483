import RunningAPI from '../../../../utilities/apiClient'

const api = new RunningAPI()

export const postRunData = async (runData) => {

  try {
    const response = await api.postData(`run/`, runData)
    return response
  } catch (error) {
    console.error('Error posting run data')
    throw error
  }
}

export default postRunData