import axios from 'axios'
/**
 * removePrefixSlash removes the prefix slash from the endpoint
 * @param {string} endpoint The endpoint to remove the prefix slash from
 * @returns {string} The endpoint without the prefix slash
 **/
const removePrefixSlash = (endpoint) => {
    if (endpoint.startsWith('/')){
        endpoint = endpoint.slice(1)
    }
    return endpoint
}

function getBaseURL() {
    const backendUrl = window.REACT_APP_BACKEND_URL ? `${window.REACT_APP_BACKEND_URL}/api` : "http://127.0.0.1:8000/api";
    return backendUrl
}

class RunningAPI {

    constructor() {
        this.baseURL = getBaseURL()
    }
    
    /**
     * sendRequest sends a request to the API
     * @param {string} method The method of the request
     * @param {string} endpoint The endpoint to send the request to
     * @param {JSON} data The data to send with the request
     * @returns {JSON} The data from the API
     **/
    async sendRequest(method, endpoint, data = null, raw = false) {
        endpoint = removePrefixSlash(endpoint)
        this.token = localStorage.getItem('token')
        const headers = {}
        if (this.token) {
            headers['Authorization'] = `Token ${this.token}`
        }
        try {
            const response = await axios({
                method: method,
                url: `${this.baseURL}/${endpoint}`,
                headers: headers,
                data: data
            })
            if (raw) {
                return response
            }
            if (response.status >= 200 && response.status < 300) {
                return response.data
            } else {
                return null
            }
        } catch (error) {
            return null
        }
    }
    
    /**
     * getData fetches data from the API
     * @param {string} endpoint The endpoint to fetch data from
     * @returns {JSON} The data from the API
     */
    async getData(endpoint) {
        return this.sendRequest('GET', endpoint)
    }

    /**
     * postData sends data to the API
     * @param {string} endpoint The endpoint to send data to
     * @param {JSON} data The data to send
     * @returns {JSON} The data from the API
     */
    async postData(endpoint, data) {
        return this.sendRequest('POST', endpoint, data)
    }

    /**
     * patchData updates data in the API
     * @param {string} endpoint The endpoint to patch data with
     * @param {JSON} data The data to update
     * @returns {JSON} The data from the API
     */
    async patchData(endpoint, data) {
        return this.sendRequest('PATCH', endpoint, data)
    }

}

export default RunningAPI;
