import React, { useState } from 'react'
import { Container, Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import RegisterComp from '../../components/auth/RegisterComp'
import RunningMan from '../../assets/running-man-small.svg'
import RunningAPI from '../../utilities/apiClient'

/**
 * RegistrationPage component.
 *
 * This component represents the registration page of the application.
 * It allows users to register by providing their registration data.
 *
 * @returns {JSX.Element} The rendered registration page.
 */
const RegistrationPage = () => {
  const navigate = useNavigate()
  const [errorData, setErrorData] = useState(null)

  const handleRegister = async (registrationData) => {
    const error = await handleRegisterRequest(registrationData)
    setErrorData(error)
    return error
  }

  const handleRegisterRequest = async (registrationData) => {
    try {
      const api = new RunningAPI()
      await api.postData('auth/registration/', registrationData)
      setTimeout(() => {
        navigate('/')
      }, 1500);
      return null
    } catch (error) {
      if (error.response) {
        return error.response.data
      }
      return { non_field_errors: ["An unknown error occurred."] }
    }
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: '#263238',
      }}
    >
      <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'center', marginBottom: '2rem' }}>
        <Typography sx={{ fontSize: { xs: '2rem', sm: '3rem', md: '4rem' }, fontStyle: 'italic', color: '#FFD54F' }}>
          TrackRunner
        </Typography>
        <Typography>
          <img src={RunningMan} alt="Man Running" style={{ width: '100%', maxWidth: '9.375rem' }}></img>
        </Typography>
      </Box>
      <RegisterComp onRegister={handleRegister} errorData={errorData} />
    </Container>
  )
}

export default RegistrationPage