import React, { useState } from 'react'
import { TextField, Button, Box, Typography, InputAdornment, IconButton, Link, FormControlLabel, Checkbox } from '@mui/material'
import { Email, Person, Lock } from '@mui/icons-material'
import validateEmail from '../../validations/EmailValidation'
import validatePassword from '../../validations/PasswordValidation'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

/**
 * RegisterComp component.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.onRegister - The function to be called when the form is submitted.
 * @param {Object} props.errorData - The error data from the registration request.
 * @returns {JSX.Element} The rendered RegisterComp component.
 */
const RegisterComp = ({ onRegister, errorData }) => {
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [showPassword1, setShowPassword1] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)

  const [emailError, setEmailError] = useState('')
  const [usernameError, setUsernameError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState('')
  const [termsError, setTermsError] = useState('')

  const handleClickShowPassword1 = () => setShowPassword1(!showPassword1)
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2)

  const handleSubmit = async (e) => {
    e.preventDefault()

    let valid = true

    setEmailError('')
    setUsernameError('')
    setPasswordError('')
    setConfirmPasswordError('')
    setTermsError('')

    if (!email) {
      setEmailError('Email is required')
      valid = false
    } else if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address')
      valid = false
    }

    if (!username) {
      setUsernameError('Username is required')
      valid = false
    } else if (/\s/.test(username)) {
      setUsernameError('Username cannot contain spaces')
      valid = false
    }

    if (!password1) {
      setPasswordError('Password is required')
      valid = false
    } else if (!validatePassword(password1)) {
      setPasswordError('Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, and a number.')
      valid = false
    }

    if (!password2) {
      setConfirmPasswordError('Please confirm your password')
      valid = false
    } else if (password1 !== password2) {
      setConfirmPasswordError('Passwords do not match')
      valid = false
    }

    if (!termsAccepted) {
      setTermsError('You must accept the terms and conditions')
      valid = false
    }

    if (valid) {
      const error = await onRegister({
        email,
        username,
        password1,
        password2,
      })

      if (error) {
        setEmailError(error.email?.[0] || '')
        setUsernameError(error.username?.[0] || '')
      }
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        padding: '2.5rem',
        borderRadius: '0.5rem',
        boxShadow: '0rem 0rem 0.625rem rgba(0, 0, 0, 0.1)',
        maxWidth: '45rem',
        width: '100%',
      }}
    >
      <form onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
          <TextField
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={Boolean(emailError)}
            helperText={emailError}
            fullWidth
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="Username"
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            error={Boolean(usernameError)}
            helperText={usernameError}
            fullWidth
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Person />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="Password"
            type={showPassword1 ? 'text' : 'password'}
            variant="outlined"
            value={password1}
            onChange={(e) => setPassword1(e.target.value)}
            error={Boolean(passwordError)}
            helperText={passwordError}
            fullWidth
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword1}
                    edge="end"
                  >
                    {showPassword1 ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="Confirm Password"
            type={showPassword2 ? 'text' : 'password'}
            variant="outlined"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            error={Boolean(confirmPasswordError)}
            helperText={confirmPasswordError}
            fullWidth
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword2}
                    edge="end"
                  >
                    {showPassword2 ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          
          <FormControlLabel
            control={
              <Checkbox
                checked={termsAccepted}
                onChange={(e) => {
                  setTermsAccepted(e.target.checked);
                  if (e.target.checked) {
                    setTermsError('')
                  }
                }}
                color="primary"
              />
            }
            label={
              <Typography sx={{ fontSize: { xs: '0.75rem', sm: '1rem' } }}>
                I accept the{' '}
                <Link href="/terms" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'underline', color: 'primary.main', cursor: 'pointer' }}>
                  Terms and Conditions
                </Link>
              </Typography>
            }
          />
          {termsError && <Typography color="error">{termsError}</Typography>}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              padding: '0.625rem 0',
              fontSize: '1rem',
              color: 'black',
              backgroundColor: '#FFD54F',
              '&:hover': {
                backgroundColor: '#263238',
                color: 'white',
              },
            }}
          >
            Create account
          </Button>

          <Link href="/" sx={{ color: '#007BFF', display: 'block', marginTop: '1rem', textAlign: 'center' }}>
            Already have an account?
          </Link>
        </Box>
      </form>
    </Box>
  )
}

export default RegisterComp