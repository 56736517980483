import { Box, Typography, Skeleton, useMediaQuery } from '@mui/material'
import { ThemeProvider} from '@mui/material/styles'
import tileTheme from '../../../theme/dashboard_themes/tileTheme'
import { useState } from 'react'
import { blueGrey } from '@mui/material/colors'
import EditRunModal from './editRunComponents/EditRunModal'

const RecentRunTile = ({theme, gridBoxStyle, tileBaseStyle, mostRecentRun, mostRecentRunLoading,
    mostRecentRunError, updateChart, updateTable, updateRecentRun, updateBestRun
 }) => {
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    
    const handleTileClick = () => {
        if (mostRecentRun) {
            setIsEditModalOpen(true)
        }
    }

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false)
    }
    return (
        <>
            <Box 
                sx={{...gridBoxStyle, cursor: mostRecentRun ? 'pointer' : 'default'}} 
                onClick={handleTileClick}
            >
                <ThemeProvider theme={tileTheme}>
                    {mostRecentRunLoading ? (
                        <Skeleton variant="square" width='100%' height='100%'/>
                    ) : mostRecentRun ? (
                        <>
                        <Typography sx={tileBaseStyle} variant={isMobile ? 'body1' : 'h6'}>Previous Run</Typography>
                        <Typography sx={{...tileBaseStyle, padding: '0'}} variant={isMobile ? 'h4' : 'h2'} color={blueGrey[50]}>{mostRecentRun.date}</Typography>
                        <Typography sx={{...tileBaseStyle, paddingBottom: '0'}} variant={isMobile ? 'h5' : 'h4'}>{mostRecentRun.duration}</Typography>
                        <Typography sx={{...tileBaseStyle, padding: '0'}} variant={isMobile ? 'body1' : 'h6'}>{mostRecentRun.route}</Typography>
                        </>
                    ) : (
                        <>
                            <Typography sx={{tileBaseStyle, textAlign: 'center'}} variant='h4'>{mostRecentRunError}</Typography>
                        </>
                    )}
                </ThemeProvider>
            </Box>
            {mostRecentRun && (
                <EditRunModal 
                    open={isEditModalOpen}
                    handleClose={handleCloseEditModal}
                    run={mostRecentRun}
                    onRunUpdated={updateRecentRun}
                    updateChart={updateChart}
                    updateTable={updateTable}
                    reloadRecentOrBest={updateBestRun}
                />
            )}
        </>
    )
}

export default RecentRunTile